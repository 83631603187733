<template>
  <div class="container-horiz-chart">
    <canvas :id="makeSpecialId()" width="100%" ></canvas>
  </div>
</template>

<script>
import i18n from '../../../translations/i18n'
import MixinsChart from '@/_mixins/charts'
import { canvasMaker, responseOptions } from '@/_helpers'
import('chart.js').then((chartjs) => { window.Chart = chartjs.default })

export default {
  name: 'BarHorizChart',
  mixins: [MixinsChart],
  props: ['data', 'question', 'cardData', 'expanded'],
  data () {
    return {
      renderChart: {},
      total: 0,
      colors: ['#a87df5', '#fc90a4', '#00aaa7', '#673ab7', '#9f95f5', '#FFB500', '#f55bed', '#CA1E54', '#4D5359', '#79C99E', '#97DB4F', '#27B573', '#FF7663', '#1B9AAA', '#FFC43D', '#6A1B9A', '#FF8A65', '#2E7D32', '#E64A19', '#1976D2']

    }
  },
  mounted () {
    this.render()
  },
  computed: {
    widthChart () { return this.expanded ? '600' : '250' }
  },
  methods: {
    scaleBar (value) {
      return value[0] * 1.5
    },
    returnPercentage (value) {
      return Number(Math.round((value * 100) / this.total + 'e' + 2) + 'e-' + 2)
    },
    emitClick (objToRedirect) {
      if (this.$route.name === 'RealTime') {
        this.$root.$emit('realtime-redirect-to-responses', objToRedirect)
      } else {
        this.$root.$emit('redirect-to-responses', objToRedirect)
      }
    },
    makeSpecialId () {
      this.question.canvasId = canvasMaker.makeCanvasId(this.question)
      return this.question.canvasId
    },
    async render () {
      let fontSize = 18
      let fontFamily = "Avenir', Helvetica, Arial, sans-serif;"
      let backgroundColor = this.question.type === 'enum' ? this.colors : this.cardData.colors
      const keyOptions = ['multiple', 'single', 'singleOther', 'multipleOther'].includes(this.question.ui) ? this.question.options : responseOptions.getOptions(this.question)
      const data = []
      const labels = []
      const self = this

      if (!this.data.length) return

      this.data[0].questions.map(item => {
        if (item.question._id === this.question._id) {
          this.total = item.total
          const isEmpty = Object.keys(item.valueGroup).length === 0
          this.cardData.npsType = this.cardData.npsType || 'metric'
          if (item.question.type === 'nps' && !isEmpty && this.cardData.npsType === 'metric') {
            labels.push(
              i18n.t('dashboard.graphics.promoter'),
              i18n.t('dashboard.graphics.neutralSing'),
              i18n.t('dashboard.graphics.detractor'))
            data.push(
              item.valueGroup.pr,
              item.valueGroup.nt,
              item.valueGroup.dt
            )
            return
          }

          item.data.map((point, index) => {
            if (index > 0 && point[0] !== undefined) {
              data.push(point[1])
              if (['multiple', 'single', 'singleOther', 'multipleOther'].includes(this.question.ui)) {
                labels.push(point[0])
                return false
              }
              keyOptions.map(op => {
                if ([op.value, op.rating].indexOf(point[0].toString()) > -1) {
                  if (['10num', '5num'].indexOf(this.question.ui) > -1) {
                    labels.push(i18n.t('dashboard.graphics.op') + ' ' + op.label)
                  } else if (this.question.ui === '5emo') {
                    fontSize = 24
                    fontFamily = 'icomoon'
                    backgroundColor = [
                      this.cardData.colors[0],
                      this.cardData.colors[1],
                      this.cardData.colors[2],
                      this.cardData.colors[3],
                      this.cardData.colors[4]
                    ]
                    if (point[0].toString() === op.value) {
                      labels.push(op.icon)
                    }
                  } else {
                    labels.push(op.label)
                  }
                }
              })
            }
          })
        }
      })
      const newLabels = []
      labels.forEach(label => {
        const textFormatted = label.length > 15 ? label.slice(0, 15) + '...' : label
        newLabels.push(textFormatted)
      })
      const planetChartData = {
        type: 'horizontalBar',
        data: {
          labels: newLabels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColor
            }
          ]
        },
        options: {
          indexAxis: 'y',
          maintainAspectRatio: false,
          responsive: true,
          tooltips: {
            titleFontFamily: fontFamily,
            titleFontSize: fontSize,
            position: 'average',
            titleMarginBottom: 1,
            callbacks: {
              title: tooltipItem => this.formatTooltipTitle(tooltipItem, labels, 50),
              label: context => {
                return `${context.value} - ${self.returnPercentage(context.value)}%`
              },
              footer: () => {
                return [`${i18n.t('real_time.see_at_responses')}`]
              }
            }
          },
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
                precision: 0,
                suggestedMax: self.scaleBar(data)
              }
            }],
            yAxes: [{
              ticks: {
                fontSize: fontSize,
                fontFamily: fontFamily
              }
            }]
          },
          legend: {
            display: false
          },
          onClick: function (event) {
            const item = this.getElementAtEvent(event)
            if (item.length > 0) {
              const itemLabel = labels[item[0]._index]
              const clickedItem = { label: itemLabel }
              const objToRedirect = {
                clickedOption: clickedItem,
                question: self.question
              }
              self.emitClick(objToRedirect)
            }
          }
        }
      }

      const ctx = document.getElementById(this.question.canvasId)
      this.renderChart = await new window.Chart(ctx, {
        type: planetChartData.type,
        data: planetChartData.data,
        options: planetChartData.options,
        plugins: [{
          beforeInit: function (chart) {
            chart.data.labels.forEach(function (e, i, a) {
              if (/\n/.test(e)) {
                a[i] = e.split(/\n/)
              }
            })
          },
          afterDatasetsDraw: function (chart) {
            const ctx = chart.ctx
            ctx.textAlign = 'left'
            ctx.font = '12' + 'px Arial'
            ctx.textBaseline = 'bottom'

            chart.config.data.datasets.forEach(dataset => {
              const data = dataset
              newLabels.forEach((o, i) => {
                const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                ctx.fillStyle = model.backgroundColor
                const label = `${data.data[i]} - ${self.returnPercentage(data.data[i]).toFixed(1)}%`
                ctx.fillText(label, model.x + 2, model.y + 6)
              })
            })
          }
        }]
      })
      return this.renderChart
    }
  },
  watch: {
    expanded: function () {
      this.renderChart.destroy()
      const self = this
      setTimeout(() => self.render(), 100)
    }
  }
}
</script>
<style lang="scss">
.container-horiz-chart {
  height: 100%;
}
</style>
