export default {
  data () {
    return {
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    coco () { return 'coco' }
  },
  methods: {
    translateChartLabels (labels, key) { return labels.map(item => this.$t(`${key}.${item}`)) },
    generateUniqueId () { return Math.random().toString(36).substring(2, 16) },
    generateRandomColors (numColors) {
      return Array.from({ length: numColors }, () => {
        let r, g, b
        do {
          r = Math.floor(Math.random() * 256)
          g = Math.floor(Math.random() * 256)
          b = Math.floor(Math.random() * 256)
        } while (r === 255 && g === 255 && b === 255) // Evita o branco

        return `rgba(${r}, ${g}, ${b}, 0.8)`
      })
    },
    shortLabels (labels) { return labels.map(l => l.length > 10 ? l.slice(0, 10) + '...' : l) },
    formatTooltipTitle (tooltipData, labels, caractere) {
      const indexOfLabel = tooltipData[0].index
      const regex = new RegExp(`.{1,${caractere}}(\\s|$)`, 'g')
      return labels[indexOfLabel].match(regex).join('\n')
    },
    formatTooltipLabel (label, maxCharacters) {
      label = `◽ ${label}`
      const regex = new RegExp(`.{1,${maxCharacters}}`, 'g')
      return label.match(regex)
    }
  }
}
