import { callApiService } from '@/_services'
import { sorter } from '@/_helpers'

const API_URL = process.env.VUE_APP_URL

export const ticketsService = {
  getTicketCategories,
  createTicket,
  getTicket,
  getTicketsByStatus,
  getUsersWithSuperadminParam,
  getEvents,
  setMessage,
  getTicketByEmail,
  getTicketById,
  getCategories,
  newCategory,
  deleteCategory,
  closeTicket,
  reopenTicket,
  editCategory,
  editSubCategory,
  categorizeTicket,
  transferTicket
}

async function getTicketCategories () {
  const ticketUrl = API_URL + 'ticket-categories'
  const result = await callApiService.callApi(ticketUrl, 'get')

  return sorter.sortByKeyOrDate(result, 'title')
}

async function createTicket (ticket) {
  const ticketUrl = API_URL + 'tickets'
  const result = await callApiService.callApi(ticketUrl, 'post', ticket)
  return result
}

async function getTicket (sendingId) {
  const ticketType = 'TicketAttendantChangeEvent,TicketCreationEvent'
  const ticketUrl = `${API_URL}tickets/${sendingId}/events?ticketType=${ticketType}`
  const result = await callApiService.callApi(ticketUrl, 'get', ticketType)
  return result
}

async function getTicketsByStatus (search, category, status, attendant, startRange, endRange) {
  let ticketUrl
  if (status === 'all') {
    ticketUrl = `${API_URL}tickets`
  } else {
    if (category) {
      ticketUrl = `${API_URL}tickets?category=${category}&status=${status}`
    } else {
      ticketUrl = `${API_URL}tickets?status=${status}`
    }
  }

  const verifyQueryParams = /\?.+=.+/.test(ticketUrl)
  if (attendant) {
    ticketUrl += `${verifyQueryParams ? '&' : '?'}attendant=${attendant}`
  }
  if (search) {
    ticketUrl += `${verifyQueryParams ? '&' : '?'}search=${search}`
  }
  const result = await callApiService.callApi(ticketUrl, 'get', null, endRange, null, startRange, null, false)
  return result
}

async function getUsersWithSuperadminParam () {
  const ticketUrl = `${API_URL}users?showSuperadmin=true`
  const result = await callApiService.callApi(ticketUrl, 'get')
  return result
}

async function getEvents (id) {
  const ticketUrl = `${API_URL}tickets/${id}/events?ignoreLoading=true`
  const result = await callApiService.callApi(ticketUrl, 'get')
  return result
}

async function setMessage (id, body) {
  const ticketUrl = `${API_URL}tickets/${id}/message`
  const result = await callApiService.callApi(ticketUrl, 'post', body)
  return result
}

async function getTicketByEmail (ticket) {
  const ticketUrl = `${API_URL}tickets?search=${ticket.contactEmail}&status=${ticket.status}`
  const result = await callApiService.callApi(ticketUrl, 'get')
  return result
}

async function getTicketById (id) {
  const ticketUrl = `${API_URL}tickets?id=${id}`
  const result = await callApiService.callApi(ticketUrl, 'get')
  return result
}

async function getCategories () {
  const ticketUrl = `${API_URL}ticket-categories`
  const result = await callApiService.callApi(ticketUrl, 'get')
  return result
}
async function newCategory (body) {
  const ticketUrl = `${API_URL}ticket-categories`
  const result = await callApiService.callApi(ticketUrl, 'post', body)
  return result
}

async function deleteCategory (id) {
  const ticketUrl = `${API_URL}ticket-categories/${id}`
  const result = await callApiService.callApi(ticketUrl, 'delete', { id: id })
  return result
}
async function editCategory (body, id) {
  const ticketUrl = `${API_URL}ticket-categories/${id}`
  const result = await callApiService.callApi(ticketUrl, 'put', body)
  return result
}
async function closeTicket (id) {
  const ticketUrl = `${API_URL}tickets/${id}/close`
  const result = await callApiService.callApi(ticketUrl, 'PATCH')
  return result
}

async function reopenTicket (id) {
  const ticketUrl = `${API_URL}tickets/${id}/open`
  const result = await callApiService.callApi(ticketUrl, 'PATCH')
  return result
}

async function categorizeTicket (body, id) {
  const ticketUrl = `${API_URL}tickets/${id}/categorize`
  const result = await callApiService.callApi(ticketUrl, 'PATCH', body)
  return result
}

async function editSubCategory (body, id) {
  const ticketUrl = `${API_URL}ticket-categories/${id}`
  const result = await callApiService.callApi(ticketUrl, 'put', body)
  return result
}

async function transferTicket (id, attendantId) {
  const ticketUrl = `${API_URL}tickets/${id}/assign`
  const result = await callApiService.callApi(ticketUrl, 'PATCH', { attendant: attendantId })
  return result
}
