<template>
  <div class="container-survey-list">
    <div class="loading-survey-list" v-show="isListLoading">
      <Circle2 class="spinner-loading-survey-list"/>
    </div>
    <Menu :showSubMenu="false" />
    <div class="content-survey-list" v-if="!isListLoading">
      <div class="header-survey-list">
        <div class="titles-survey-list">
          <app-heading level="h3">{{ $t('surveyList.my_surveys') }}</app-heading>
          <app-heading level="h6" class="surveys-total" v-if="surveys.length">{{ surveys.length }} {{ labelTotal }}</app-heading>
        </div>
        <div class="options-survey-list">
          <div class="options-one-survey-list">
            <button
              class="btn-survey-list second-btn"
              :class="{'btn-outline-not-allowed': !canCreateOrCloneSurveys}"
              :disabled='!canCreateOrCloneSurveys'
              @click="goToTemplates()"
              >
                {{ $t('surveyList.templates') }}
            </button>

            <button
              class="btn-survey-list"
              :class="{'btn-not-allowed': !canCreateOrCloneSurveys}"
              :disabled='!canCreateOrCloneSurveys'
              @click="isModalOpen = true"
              >
                {{ $t('surveyList.new_survey') }}
          </button>
          </div>
          <div class="options-two-survey-list">
            <div class="survey-tabs">
              <!-- Status Tabs -->
              <div class="tab-group">
                <div
                  v-for="tab in ['all', 'active', 'expired']"
                  :key="tab"
                  class="tab-item"
                  :class="{ active: activeTab === tab }"
                  @click="activeTab = tab"
                >
                  <i class="material-icons">{{ getTabIcon(tab) }}</i>
                  <span> {{ getTabLabel(tab) }} </span>
                  <span class="tag-count">{{ getTabCount(tab) }}</span>
          </div>
        </div>

              <!-- Tag Tabs -->
              <div class="tab-group" v-if="availableTags.length > 0">
                <div
                  v-for="tag in limitedAvailableTags"
                  :key="tag.id"
                  class="tab-item"
                  :class="{ active: selectedTags.includes(tag.id) }"
                  :style="{ borderLeft: `3px solid ${tag.color}` }"
                  @click="toggleTag(tag.id)"
                >
                  <span v-title="tag.name.length > 20 ? tag.name : null" title-placement="right">
                    {{ tag.name }}
                  </span>
                  <span class="tag-count">{{ tag.count }}</span>
      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="facets-sidebar">
          <div class="search-facet">
            <i class="material-icons">search</i>
            <input
              type="text"
              :placeholder="$t('surveyList.search_by_title')"
              v-model="search"
              @input="updatePagination()"
            >
          </div>
          <div class="facet-section">
            <div class="facet-title">{{ $t('surveyList.filter_options.status') }}</div>
            <div
              class="facet-item"
              :class="{ active: activeTab === 'all' }"
              @click="activeTab = 'all'"
            >
              <div class="facet-info">
                <i class="material-icons">list_alt</i>
                <span class="facet-name">
                  {{ $t('surveyList.all_surveys') }}
                </span>
              </div>
              <span class="facet-count">{{ surveys.length }}</span>
            </div>
            <div
              class="facet-item"
              :class="{ active: activeTab === 'active' }"
              @click="activeTab = 'active'"
            >
              <div class="facet-info">
                <i class="material-icons">check_circle</i>
                <span class="facet-name">
                  {{ $t('surveyList.active_surveys') }}
                </span>
              </div>
              <span class="facet-count">{{ activeSurveysCount }}</span>
            </div>
            <div
              class="facet-item"
              :class="{ active: activeTab === 'expired' }"
              @click="activeTab = 'expired'"
            >
              <div class="facet-info">
                <i class="material-icons">timer_off</i>
                <span class="facet-name">
                  {{ $t('surveyList.expired_surveys') }}
                </span>
              </div>
              <span class="facet-count">{{ expiredSurveysCount }}</span>
            </div>
          </div>

          <div class="facet-section">
            <div class="facet-title">{{ $t('surveyList.filter_options.tags') }}</div>
            <div v-if="availableTags.length === 0" class="no-tags">
              {{ $t('surveyList.no_tags_available') }}
            </div>
            <div v-else class="tags-container">
              <div
                v-for="tag in availableTags"
                :key="tag.id"
                class="tag-item"
                :class="{ active: selectedTags.includes(tag.id) }"
                @click="toggleTag(tag.id)"
              >
                <div class="tag-info">
                  <div class="tag-color-indicator" :style="{ backgroundColor: tag.color }"></div>
                  <span
                    class="tag-name"
                    v-title="tag.name.length > 20 ? tag.name : null"
                  >
                    {{ tag.name }}
                  </span>
                </div>
                <span class="tag-count">{{ tag.count }}</span>
              </div>
            </div>
          </div>

          <div class="facet-section">
            <div class="facet-title">{{ $t('surveyList.filter_options.sort_by') }}</div>
            <div class="facet-options">
              <div
                v-for="option in filterOptionsData"
                :key="option.value"
                class="facet-item"
                :class="{ active: filterOptions?.value === option.value }"
                @click="filterOptions = option; scrollToTop()"
              >
                <div class="facet-info">
                  <i class="material-icons">{{ getFilterMaterialIcon(option.value) }}</i>
                  <span
                    class="facet-name"
                    v-title="option.label.length > 20 ? option.label : null"
                    title-placement="right"
                  >
                    {{ option.label }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="surveys-content">
          <div class="section-survey-list" :key="key">
            <CardSurvey
              v-for="(item, idx) in currentPageItems"
              :key="idx"
              :dataSurvey="item"
              @delete-survey="deleteSurvey($event, idx)"
              @to-redirect="redirectTo($event)"
              @to-duplicate="cloneSurvey($event)"
              @scroll-to-top="handleScrollToTop"
            />
          </div>
          <div class="section-items-paginate" v-if="surveys.length > 0">
            <app-paginate :initValue="1" :prevText="'<'" :nextText="'>'" :value="page" @input="changePage" :pageRange="itemPerPage" :pageCount="dynamicPageCount"></app-paginate>
          </div>
          </div>
        </div>
      </div>
      <app-modal
        v-model="isModalOpen"
        :title="$t('start.modal_survey_title')"
        lightbox
        close
        :primary-button="$t('start.create')"
        @primaryButtonClick="newSurvey"
        @keyup.esc="this.isModalOpen = false"
        :class="{ 'disabled-btn': isCreationLoading }"
        class="modal-default-primary show-lg show-md show-sm hide-xs hide-xxs"
      >
        <app-form-input
          v-model="surveyTitle"
          :placeholder="$t('start.survey_title_placeholder')"
          secondary
          autofocus
          @keyup.enter="newSurvey"
          @keyup.esc="this.isModalOpen = false"
        />
        <div class="loading-survey-list" v-show="isCreationLoading">
          <Circle2 class="spinner-loading-survey-list"/>
        </div>
      </app-modal>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import CardSurvey from '../components/cards/CardSurvey.vue'
import { surveyService, sendhubService } from '@/_services'
import gsap from 'gsap'
import userMixins from '@/_mixins/user'
import _ from 'lodash'

export default {
  name: 'SurveyList',
  mixins: [userMixins],
  components: {
    Menu,
    CardSurvey,
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    'app-paginate': () => import('@binds-tech/binds-design-system/src/components/Paginate/Paginate'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      key: 0,
      surveys: [],
      page: 1,
      itemPerPage: 10,
      dynamicPageCount: 0,
      isListLoading: true,
      isModalOpen: false,
      filterOptions: { label: this.$t('surveyList.filter_options.no_filter'), value: undefined },
      activeTab: 'all',
      filterOptionsData: [
        { label: this.$t('surveyList.filter_options.no_filter'), value: undefined },
        { label: this.$t('surveyList.filter_options.title_az'), value: 'title_az' },
        { label: this.$t('surveyList.filter_options.title_za'), value: 'title_za' },
        { label: this.$t('surveyList.filter_options.recent_date_send'), value: 'recent_date_sent' },
        { label: this.$t('surveyList.filter_options.old_date_send'), value: 'old_date_sent' },
        { label: this.$t('surveyList.filter_options.most_sent'), value: 'most_sent' },
        { label: this.$t('surveyList.filter_options.least_sent'), value: 'least_sent' },
        { label: this.$t('surveyList.filter_options.most_answered'), value: 'most_answered' },
        { label: this.$t('surveyList.filter_options.least_answered'), value: 'least_answered' },
        { label: this.$t('surveyList.filter_options.average_time_ascending'), value: 'average_time_ascending' },
        { label: this.$t('surveyList.filter_options.average_time_descending'), value: 'average_time_descending' },
        { label: this.$t('surveyList.filter_options.expired'), value: 'expired' },
        { label: this.$t('surveyList.filter_options.not_expired'), value: 'not_expired' },
        { label: this.$t('surveyList.filter_options.health_ascending'), value: 'health_ascending' },
        { label: this.$t('surveyList.filter_options.health_descending'), value: 'health_descending' }
      ],
      search: '',
      surveyTitle: '',
      isCreationLoading: false,
      showTemplatesModal: false,
      from: undefined,
      selectedTags: [],
      canScrollLeft: false,
      canScrollRight: false,
      tabsContainer: null,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 1920
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => { vm.from = from.name })
  },
  mounted () {
    this.getAllSurveys()
    this.checkScrollButtons()
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    labelTotal () {
      let label = this.$t('menu.surveys')
      if (this.surveys.length === 1) { label = this.$t('questionsNav.survey') }
      return label
    },
    getUser () { return this.$store.getters['account/getUser'] },
    lang () { return this.$store.getters['account/getLang'] },
    activeSurveysCount () {
      return this.surveys.filter(survey => !survey.isExpired).length
    },
    expiredSurveysCount () {
      return this.surveys.filter(survey => survey.isExpired).length
    },
    filteredSurveys () {
      let result = [...this.surveys]

      // Filtra por status (ativo/expirado)
      if (this.activeTab === 'active') {
        result = result.filter(survey => !survey.isExpired)
      } else if (this.activeTab === 'expired') {
        result = result.filter(survey => survey.isExpired)
      }

      // Filtra por tags selecionadas
      if (this.selectedTags.length > 0) {
        result = result.filter(survey => {
          if (!survey.tags || !Array.isArray(survey.tags)) {
            return false
          }
          return this.selectedTags.some(selectedTagId =>
            survey.tags.some(tag => tag._id === selectedTagId)
          )
        })
      }

      // Filter by search text
      if (this.search) {
        const searchTerm = this.search.toLowerCase()
        result = result.filter(survey =>
          survey.title.toLowerCase().includes(searchTerm) ||
          survey._id.toLowerCase().includes(searchTerm)
        )
      }

      return result
    },
    currentPageItems () {
      let filterIndex = this.page
      if (this.page > this.filteredSurveys.length) {
        filterIndex = 1
      }
      return this.surveysWithBothFilterApplied.slice((filterIndex * this.itemPerPage) - this.itemPerPage, filterIndex * this.itemPerPage)
    },
    surveysWithBothFilterApplied () {
      let result = structuredClone(this.filteredSurveys)

      if (this.activeTab !== 'all') {
        result = result.filter(survey => {
          if (this.activeTab === 'active') {
            return !survey.isExpired
          } else if (this.activeTab === 'expired') {
            return survey.isExpired
          }
          return true
        })
      }

      switch (this.filterOptions?.value) {
        case 'title_az':
          result.sort((a, b) => (a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1))
          break
        case 'title_za':
          result.sort((a, b) => (a.title?.toLowerCase() < b.title?.toLowerCase() ? 1 : -1))
          break
        case 'recent_date_sent':
          this.sortingByLastSend(result, 'recent_date_sent')
          break
        case 'old_date_sent':
          this.sortingByLastSend(result, 'old_date_sent')
          break
        case 'most_sent':
          result.sort((a, b) => (a.stats.totalSendings > b.stats.totalSendings ? -1 : 1))
          break
        case 'least_sent':
          result.sort((a, b) => (a.stats.totalSendings < b.stats.totalSendings ? -1 : 1))
          break
        case 'most_answered':
          result.sort((a, b) => (a.stats.totalAnswers > b.stats.totalAnswers ? -1 : 1))
          break
        case 'least_answered':
          result.sort((a, b) => (a.stats.totalAnswers < b.stats.totalAnswers ? -1 : 1))
          break
        case 'average_time_ascending':
          result.sort((a, b) => (a.stats.responseAverageTime < b.stats.responseAverageTime ? -1 : 1))
          break
        case 'average_time_descending':
          result.sort((a, b) => (a.stats.responseAverageTime > b.stats.responseAverageTime ? -1 : 1))
          break
        case 'expired':
          result.sort((a, b) => (a.isExpired > b.isExpired ? -1 : 1))
          break
        case 'not_expired':
          result.sort((a, b) => (a.isExpired < b.isExpired ? -1 : 1))
          break
        case 'health_ascending':
          this.filterHealth(result, 'health_ascending')
          break
        case 'health_descending':
          this.filterHealth(result, 'health_descending')
          break
        case undefined:
          result = {}
          result = this.filteredSurveys
          break
        default:
          break
      }

      return result
    },
    availableTags () {
      const tagCountMap = new Map()
      this.surveys.forEach(survey => {
        if (survey.tags && Array.isArray(survey.tags)) {
          survey.tags.forEach(tag => {
            const count = tagCountMap.get(tag._id) || 0
            tagCountMap.set(tag._id, count + 1)
          })
        }
      })
      const tagsWithCount = []
      tagCountMap.forEach((count, tagId) => {
        const surveyWithTag = this.surveys.find(survey =>
          survey.tags && survey.tags.some(tag => tag._id === tagId)
        )
        if (surveyWithTag) {
          const tagInfo = surveyWithTag.tags.find(tag => tag._id === tagId)
          tagsWithCount.push({
            id: tagId,
            name: tagInfo.name,
            color: tagInfo.color,
            count
          })
        }
      })
      // Ordenar por quantidade (maior para menor) e depois por nome
      return tagsWithCount.sort((a, b) => {
        if (b.count !== a.count) {
          return b.count - a.count
        }
        return a.name.localeCompare(b.name)
      })
    },
    maxVisibleTags () {
      return this.windowWidth <= 1440 ? 4 : 5
    },
    limitedAvailableTags () {
      return this.availableTags.slice(0, this.maxVisibleTags)
    }
  },
  methods: {
    goToTemplates () {
      this.$router.push({ name: 'Templates' })
    },
    async newSurvey () {
      if (!this.surveyTitle.length) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('start.template_title_error'),
          position: 'bottomRight',
          showAlert: true
        })
      }

      this.isCreationLoading = true

      this.$store.commit('survey/startEmptySurvey', this.$store.state)

      try {
        const createdSurvey = await surveyService.createSurvey({ title: this.surveyTitle.trim(), lang: this.$i18n.vm.fallbackLocale.toLowerCase() })
        const settings = this.$store.getters['questionSettings/getDefaultSettings']

        createdSurvey.questions.map(item => {
          item.settings = settings
          item.title = item.title ? item.title : this.$i18n.t('surveyList.click_add_title')
          item.description = item.description ? item.description : this.$i18n.t('surveyList.click_add_description')
          item.icon = item.icon ? item.icon : 'blur_on'

          if (!item.stars) {
            item.stars = { active: false, numbers: false }
          }
        })

        const getTheme = createdSurvey.colors.reduce((acc, cur) => ({ ...acc, [cur.alias]: cur.value }), {})
        createdSurvey.colors = getTheme

        createdSurvey.questionSelected = 0
        this.$store.commit('survey/survey', createdSurvey)
        this.$router.push({ name: 'Surveys', params: { id: createdSurvey._id } })
      } catch (error) {
        console.error('Error when creating survey', error)

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('start.error_creating'),
          position: 'bottomRight',
          showAlert: true
        })
      } finally {
        this.isCreationLoading = false
      }
    },
    async deleteSurvey (id, idx) {
      try {
        await surveyService.deleteSurvey(id)

        this.surveys.splice(idx, 1)

        const currentSurvey = this.$store.getters['dashboard/getCurrentSurvey']

        if (this.surveys.length === 0 || (currentSurvey && id === currentSurvey._id)) {
          this.$store.commit('dashboard/updateCurrentSurvey', undefined)
          this.$store.commit('dashboard/updateSelectedSurveyId', undefined)
        }

        this.key++

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.delete_success'),
          position: 'bottomRight',
          showAlert: true
        })
      } catch (error) {
        console.error('Error when deleting the survey', error)

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.delete_error'),
          position: 'bottomRight',
          showAlert: true
        })
      }
    },
    async cloneSurvey (payload) {
      this.setLoading()

      const { surveyId, name } = payload

      try {
        const survey = await surveyService.getSurvey(surveyId)

        if (!survey) {
          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('surveyList.survey_cloned_error'),
            position: 'bottomRight',
            showAlert: true
          })
        }

        const clone = await surveyService.cloneSurvey(surveyId, { title: name })

        if (!clone) return

        this.goToSurveyEdit(clone._id)
      } catch (error) {
        console.error('Error when cloning survey', error)

        return this.$store.commit('alerts/alert', {
          message: 'Erro ao clonar pesquisa',
          position: 'topRight',
          showAlert: true
        })
      } finally {
        this.setLoading(true)
      }
    },
    async goToSurveyEdit (surveyId) {
      if (this.getUser.type === 'user' && !this.getUser.features.surveyEdit) {
        const collector = await sendhubService.collectors()
        const isAgreedSend = collector.find(o => o.survey === surveyId)

        if (isAgreedSend && this.getUser.features.seedBatches) {
          return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
        }

        return this.$router.push({ name: 'YouCant' })
      }

      return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
    },
    async redirectTo (payload) {
      this.setLoading()

      const { location, surveyId } = payload

      const query = { $and: [{ survey: { $eq: surveyId } }] }

      try {
        const survey = await surveyService.getSurvey(surveyId)

        this.$store.commit('dashboard/updateCurrentSurvey', survey)
        this.$store.commit('dashboard/updateSelectedSurveyId', surveyId)
        this.$store.commit('filters/updateFilterAnd', [{ survey: { $eq: surveyId } }])
        this.$router.push({ name: location, query: { q: JSON.stringify(query) } })
      } catch (error) {
        console.log('Error accessing route', location)
        console.error('Error:', error)

        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('error_go_to'),
          position: 'bottomRight',
          showAlert: true
        })
      } finally {
        this.setLoading(true)
      }
    },
    async getAllSurveys () {
      const result = await surveyService.getAllSurveys()

      if (!result.length && this.from === 'Start') {
        this.showTemplatesModal = true
        return
      }

      this.surveys = result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      this.setLoading(true)
      this.updatePagination()
      this.$root.$emit('exceeded')
    },
    setLoading (close) {
      if (!close) { this.isListLoading = true }

      const opacity = close ? 0 : 1
      const divLoading = document.querySelector('.loading-survey-list')
      gsap.to(divLoading, { opacity, duration: 0.2, onComplete: () => { if (close) { this.isListLoading = false } } })
    },
    sortingByLastSend (surveys, orderBy) {
      surveys.sort((a, b) => {
        const getDate = obj => (obj.lastSendAt ? new Date(obj.lastSendAt) : null)

        const dateA = getDate(a)
        const dateB = getDate(b)

        return (dateA && dateB) ? (orderBy === 'recent_date_sent' ? dateB - dateA : dateA - dateB) : (dateA ? -1 : (dateB ? 1 : 0))
      })
    },
    updatePagination () {
      const listSurveys = this.filteredSurveys
      const surveysLength = (listSurveys.length / 10)
      if (Number.isInteger(surveysLength)) {
        this.dynamicPageCount = surveysLength
      } else {
        this.dynamicPageCount = Math.trunc(surveysLength) + 1
      }
      this.key++
    },
    changePage (page) {
      this.page = page
      window.scrollTo({ top: 200, behavior: 'smooth' })
      this.key++
    },
    filterHealth (list, type) {
      list.sort((a, b) => {
        if (a.stats?.health !== undefined && b.stats?.health === undefined) {
          return -1
        }
        if (a.stats?.health === undefined && b.stats?.health !== undefined) {
          return 1
        }
        if (a.stats?.health === undefined && b.stats?.health === undefined) {
          return 0
        }

        if (type === 'health_ascending') {
          return a.stats.health - b.stats.health
        } else if (type === 'health_descending') {
          return b.stats.health - a.stats.health
        }

        return 0
      })
    },
    getFilterMaterialIcon (value) {
      const icons = {
        title_az: 'sort_by_alpha',
        title_za: 'sort_by_alpha',
        recent_date_sent: 'calendar_today',
        old_date_sent: 'calendar_today',
        most_sent: 'send',
        least_sent: 'send',
        most_answered: 'check_circle',
        least_answered: 'check_circle',
        average_time_ascending: 'access_time',
        average_time_descending: 'access_time',
        expired: 'access_time',
        not_expired: 'access_time',
        health_ascending: 'favorite',
        health_descending: 'favorite'
      }
      return icons[value] || 'filter_list'
    },
    getTagColor (tagId) {
      const tag = this.availableTags.find(t => t.id === tagId)
      return tag ? tag.color : '#ccc'
    },
    getTagName (tagId) {
      const tag = this.availableTags.find(t => t.id === tagId)
      return tag ? tag.name : ''
    },
    toggleTag (tagId) {
      const index = this.selectedTags.indexOf(tagId)
      if (index === -1) {
        this.selectedTags.push(tagId)
      } else {
        this.selectedTags.splice(index, 1)
      }
      this.updatePagination()
    },
    getTabIcon (tab) {
      const icons = {
        all: 'list_alt',
        active: 'check_circle',
        expired: 'timer_off'
      }
      return icons[tab] || 'list_alt'
    },
    getTabLabel (tab) {
      const labels = {
        all: 'Todas',
        active: 'Ativas',
        expired: 'Expiradas'
      }
      return labels[tab] || 'Todas'
    },
    getTabCount (tab) {
      const counts = {
        all: this.surveys.length,
        active: this.activeSurveysCount,
        expired: this.expiredSurveysCount
      }
      return counts[tab] || 0
    },
    checkScrollButtons () {
      const container = this.$refs.tabsContainer
      if (container) {
        this.canScrollLeft = container.scrollLeft > 0
        this.canScrollRight = container.scrollLeft < container.scrollWidth - container.clientWidth
      }
    },
    scrollTabs (direction) {
      const container = this.$refs.tabsContainer
      if (container) {
        const scrollAmount = 200
        if (direction === 'left') {
          container.scrollLeft -= scrollAmount
        } else if (direction === 'right') {
          container.scrollLeft += scrollAmount
        }
        this.checkScrollButtons()
      }
    },
    handleResize () {
      this.windowWidth = window.innerWidth
      this.checkScrollButtons()
    },
    scrollToTop () {
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleScrollToTop () {
      this.scrollToTop()
    }
  },
  watch: {
    activeTab () {
      this.page = 1
      this.updatePagination()
    },
    filterOptions () {
      this.getAllSurveys()
    },
    search: {
      handler: _.debounce(function () {
        this.getAllSurveys()
      }, 300)
    },
    availableTags: {
      handler () {
        this.$nextTick(() => {
          this.checkScrollButtons()
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-survey-list {
  background: #EFF3F840;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-survey-list {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}

.container-survey-list {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content-survey-list {
    flex: 1;
    padding: 2.7vw 3.5vw 0 3.5vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    .header-survey-list {
      width: 100%;
      height: auto;
      margin-bottom: 0.5vw;
      .titles-survey-list {
        height: 60%;
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
      }
      .options-survey-list {
        display: flex;
        gap: 2rem;
        margin-bottom: 1vw;
        margin-top: 1vw;
        padding: 0 0 1px 0;
        .options-one-survey-list {
          width: 280px;
          display: flex;
          align-items: center;
          gap: 8px;

          .btn-survey-list {
            flex: 1;
            height: 36px;
            padding: 0 12px;
            border-radius: 6px;
            font-size: 13px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease;
            background: var(--primary-color);
            color: var(--default-text-color);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

            &:hover:not(:disabled) {
              transform: translateY(-1px);
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            }

            &.second-btn {
              background: transparent;
              border: 1px solid var(--primary-color);
              color: var(--primary-color);
            }

            &.btn-not-allowed {
              background: #cacaca;
              cursor: not-allowed;
            }

            &.btn-outline-not-allowed {
              background: none;
              cursor: not-allowed;
              border: 1px solid #cacaca;
              color: #cacaca;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .options-two-survey-list {
          flex: 1;
          display: flex;
          align-items: flex-end;
          padding-right: 0;
          margin-bottom: -1px;

          @media (max-width: 768px) {
            .survey-tabs {
              display: none !important;
            }
          }

          .survey-tabs {
            flex: 1;
            justify-content: space-between;
            display: flex;
            align-items: flex-end;
            gap: 4px;
            padding-bottom: 0;
            border-bottom: 1px solid #e0e0e0;
            height: 40px;
            position: relative;
            overflow: hidden;

            .tab-group {
              display: flex;
              gap: 4px;
              height: 100%;
              flex-shrink: 0;

              &:not(:first-child) {
                margin-left: 12px;
              }

              &:first-child {
                flex: 0 0 auto;
                width: auto;
              }

              &:last-child {
                flex: 1;
                width: auto;
                overflow: hidden;
              }
            }

            .tab-item {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              padding: 0 10px;
              height: 100%;
              border-radius: 8px 8px 0 0;
              cursor: pointer;
              transition: all 0.2s ease;
              color: var(--text-color-secondary);
              font-size: 13px;
              font-weight: 500;
              background: #f8f9fa;
              border: 1px solid #e0e0e0;
              border-bottom: none;
              position: relative;
              margin-bottom: -1px;
              box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.02);
              width: 145px;
              min-width: 145px;

              @media (max-width: 1440px) {
                width: 140px;
                min-width: 140px;
                font-size: 12px;
              }

              @media (max-width: 1280px) {
                width: 120px;
                min-width: 120px;
                padding: 0 8px;
              }

              @media (max-width: 1024px) {
                width: 110px;
                min-width: 110px;
                font-size: 11px;
                .material-icons {
                  font-size: 16px;
                }
              }

              @media (max-width: 768px) {
                width: auto;
                min-width: 100px;
                padding: 0 6px;
                gap: 4px;
              }

              .material-icons {
                font-size: 18px;
                color: var(--text-color-secondary);
                flex-shrink: 0;

                @media (max-width: 768px) {
                  font-size: 16px;
                }
              }

              .tag-color-indicator {
                width: 3px;
                height: 100%;
                flex-shrink: 0;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px 0 0 0;
              }

              span:not(.tag-count) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;

                @media (max-width: 768px) {
                  margin-left: 4px;
                }
              }

              .tag-count {
                padding: 2px 6px;
                font-size: 12px;
                font-weight: 500;
                border-radius: 4px;
                background: var(--background-color-secondary);
                color: var(--text-color-secondary);
                min-width: 24px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                margin-left: 4px;
                height: 20px;

                @media (max-width: 1440px) {
                  font-size: 11px;
                  padding: 1px 5px;
                  min-width: 22px;
                  height: 18px;
                }

                @media (max-width: 1280px) {
                  font-size: 11px;
                  padding: 1px 4px;
                  min-width: 20px;
                  height: 18px;
                }

                @media (max-width: 1024px) {
                  font-size: 10px;
                  padding: 1px 4px;
                  min-width: 18px;
                  height: 16px;
                }

                @media (max-width: 768px) {
                  font-size: 10px;
                  padding: 1px 4px;
                  min-width: 16px;
                  height: 16px;
                }
              }

              &.active {
                color: var(--accent-color);
                background: white;
                border-color: #e0e0e0;
                z-index: 1;
                box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);

                &::after {
                  content: '';
                  position: absolute;
                  bottom: -1px;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background: white;
                }

                .material-icons {
                  color: var(--accent-color);
                }

                .tag-count {
                  background: var(--accent-color);
                  color: white;
                }
              }
            }
          }
        }
      }
    }
    .content-wrapper {
      margin-top: 0;
      display: flex;
      gap: 2rem;
      flex: 1;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
  .main-content {
    display: flex;
    gap: 2rem;
    flex: 1;
    margin-top: 1rem;
  }
  .facets-sidebar {
    width: 280px;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--border-color);
    position: relative;
    height: fit-content;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }

    .search-facet {
      margin-bottom: 16px;
      position: relative;

      .material-icons {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--text-color-secondary);
        font-size: 18px;
      }

      input {
        width: 100%;
        height: 36px;
        padding: 8px 12px 8px 32px;
        border-radius: 6px;
        border: 1px solid var(--border-color);
        font-size: 13px;
        color: var(--text-color);
        transition: all 0.2s ease;
        background: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

        &:focus {
          outline: none;
          border-color: var(--accent-color);
          box-shadow: 0 0 0 2px var(--accent-color-light), 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        &::placeholder {
          color: var(--text-color-secondary);
        }
      }
    }

    .facet-section {
      margin-bottom: 16px;
      background: #f8f9fa;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--border-color);

      &:last-child {
        margin-bottom: 0;
      }

      .facet-title {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-color);
        margin-bottom: 12px;
        padding-bottom: 6px;
        border-bottom: 1px solid var(--border-color);
      }

      .facet-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 8px;
        margin-bottom: 6px;
        border-radius: 6px;
        cursor: pointer;
        height: 32px;
        width: 218px;
        transition: all 0.2s ease;
        background: white;
        border: 1px solid var(--border-color);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          transform: translateX(2px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        &.active {
          background: var(--accent-color-light);
          border-color: var(--accent-color);
          box-shadow: 0 2px 4px rgba(var(--accent-color-rgb), 0.15);

          .facet-info {
            .material-icons {
              color: var(--accent-color);
            }
            .facet-name {
              color: var(--accent-color);
            }
          }

          .facet-count {
            background: var(--accent-color);
            color: white;
          }
        }

        .facet-info {
          display: flex;
          align-items: center;
          gap: 6px;
          flex: 1;
          min-width: 0;
          max-width: calc(100% - 45px);

          .material-icons {
            font-size: 16px;
            color: var(--text-color-secondary);
            flex-shrink: 0;
          }

          .facet-name {
            font-size: 13px;
            color: var(--text-color);
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            word-break: keep-all;
            position: relative;
          }
        }

        .facet-count {
          padding: 2px 6px;
          font-size: 12px;
          font-weight: 500;
          border-radius: 4px;
          background: var(--background-color-secondary);
          color: var(--text-color-secondary);
          min-width: 24px;
          text-align: center;
          flex-shrink: 0;
          margin-left: 6px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .tags-container {
        max-height: 250px;
        overflow-y: auto;
        padding-right: 6px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: var(--background-color);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--accent-color);
          border-radius: 4px;
        }

        .tag-item {
          display: flex;
          align-items: center;
          padding: 6px 8px;
          border-radius: 6px;
          cursor: pointer;
          transition: all 0.2s ease;
          margin-bottom: 6px;
          height: 32px;
          background: white;
          border: 1px solid var(--border-color);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            transform: translateX(2px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          &.active {
            background: var(--accent-color-light);
            border-color: var(--accent-color);
            box-shadow: 0 2px 4px rgba(var(--accent-color-rgb), 0.15);

            .tag-name {
              color: var(--accent-color);
            }
            .tag-count {
              background: var(--accent-color);
              color: white;
            }
          }

          .tag-info {
            display: flex;
            align-items: center;
            gap: 6px;
            flex: 1;
            min-width: 0;
            max-width: calc(100% - 45px);

            .tag-color-indicator {
              width: 8px;
              height: 8px;
              border-radius: 2px;
              flex-shrink: 0;
            }

            .tag-name {
              font-size: 13px;
              color: var(--text-color);
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              word-break: keep-all;
            }
          }

          .tag-count {
            padding: 2px 6px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 4px;
            background: var(--background-color-secondary);
            color: var(--text-color-secondary);
            min-width: 24px;
            text-align: center;
            flex-shrink: 0;
            margin-left: 6px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .surveys-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .section-survey-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }
  .section-items-paginate {
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.no-tags {
  padding: 12px;
  text-align: center;
  color: var(--text-color-secondary);
    background: var(--background-color);
  border-radius: 6px;
  border: 1px dashed var(--border-color);
  font-size: 13px;
}

.facet-name, .tag-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: var(--text-color);
}

.facets-sidebar {
  .facet-section {
    .facet-item, .tag-item {
      .facet-info, .tag-info {
        .facet-name, .tag-name {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.tags-modal-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 55vh;
  overflow-y: auto;
  padding: 0 4px;

  .tags-section {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid var(--border-color);

    .section-header {
      margin-bottom: 6px;
      h4 {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-color);
      }
    }

    .tags-list {
      max-height: 120px;
      overflow-y: auto;
      padding-right: 4px;
      margin: 0;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: var(--background-color);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--accent-color);
        border-radius: 4px;
      }

      .tag-item {
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal-default-primary.confirm-modal-survey {
  .modal-content {
    min-width: 450px;
    max-width: 500px;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    color: var(--text-color);
    background: white;
    border-radius: 8px;

    .modal-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
      color: var(--text-color);
    }

    .modal-body {
      margin: 12px 0;
      padding: 0 12px;
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      gap: 8px;
      margin-top: 16px;

      button {
        min-width: 100px;
        padding: 6px 12px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;

        &.primary {
          background: var(--primary-color);
          color: white;
        }

        &.secondary {
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          background: white;
        }
      }
    }
  }
}
</style>
