export const sorter = {
  sortByKeyOrDate
}

/**
 * Retorna um array ordenado alfabeticamente, baseado na KEY recebida por parametro
 * @param {Array} array contendo o array a ser ordernado
 * @param {String} key contendo a key do array para realizar a comparação
 */
function sortByKeyOrDate (array, key) {
  const isDate = ['createdAt', 'updatedAt'].includes(key)

  if (isDate) {
    return array.sort((objA, objB) => new Date(objB[key]) - new Date(objA[key]))
  }

  return array.sort((objA, objB) => {
    const a = reduceString(objA[key])
    const b = reduceString(objB[key])
    return a.localeCompare(b)
  })
}

function reduceString (string) {
  if (string) {
    return string.toLowerCase().replace(/[àáâãäå]/, 'a').replace(/[èéêë]/, 'e').replace(/[ìíîï]/, 'i').replace(/[òóôõö]/, 'o').replace(/[ùúûü]/, 'u').replace(/[ç]/, 'c').replace(/[^a-z0-9]/gi, '')
  }
}
