import { callApiService } from '@/_services'
import { sorter } from '@/_helpers'

const API_URL = process.env.VUE_APP_URL

export const tagsService = {
  getTags,
  createTag,
  deleteTag,
  updateTag,
  updateTagToResponse
}

async function getTags () {
  const tagUrl = API_URL + 'tags'
  const result = await callApiService.callApi(tagUrl, 'get')

  return sorter.sortByKeyOrDate(result, 'name')
}

async function deleteTag (tagId, body) {
  const tagUrl = API_URL + 'tags/' + tagId
  const result = await callApiService.callApi(tagUrl, 'delete', body)
  return result
}

async function createTag (body) {
  const tagUrl = API_URL + 'tags'
  const result = await callApiService.callApi(tagUrl, 'post', body)
  return result
}

async function updateTag (tagId, body) {
  const tagUrl = API_URL + 'tags/' + tagId
  const result = await callApiService.callApi(tagUrl, 'put', body)
  return result
}

async function updateTagToResponse (sendingId, tags, isDelete) {
  const params = { sendingId: sendingId, tags: tags }
  if (isDelete) {
    params.action = 'remove'
  }
  const tagUrl = API_URL + 'sendings/tags'
  const result = await callApiService.callApi(tagUrl, 'post', params)
  return result
}
