export const callApiService = {
  callApi
}

function insertToken () {
  const token = JSON.parse(localStorage.getItem('bindsUser'))
  if (token) {
    return 'Bearer ' + token
  }
  logout()
  throw new Error('Invalid token')
}

function logout () {
  localStorage.removeItem('bindsAccount')
  localStorage.removeItem('bindsUser')
  window.location.replace('/')
}

/**
 * Call API project
 *
 * @param {String} url contains ,the url of requisition
 * @param {String} method contains the method of requisition
 * @param {Object | Null} body contains the body of requisition
 * @param {Integer | Null} newRangeEnd contains the <end-range> for the Range request header || null.
 * @param {Integer | Null} newRangeStart contains the <start-range> for the Range request header || null.
 * @param {Boolean | Null} returnMessage says if it should return de error object from req, but when it is turned off, it triggers an alert.
 * @returns {Object}
 */
async function callApi (url, method, body, newRangeEnd, isFile, newRangeStart, returnMessage = null, returnRange = true) {
  try {
    const token = localStorage.getItem('bindsUser')
    const headers = {
      Authorization: () => 'Bearer ' + token,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
    if (newRangeEnd) {
      if (!newRangeStart) {
        headers.Range = 'items=0-' + newRangeEnd
      } else {
        headers.Range = `items=${newRangeStart}-${newRangeEnd}`
      }
    }
    headers.Authorization = insertToken()
    const request = {
      method,
      headers
    }
    if (isFile) {
      request.headers = { Authorization: headers.Authorization }
    }
    if (method !== 'get' && body !== '') {
      request.body = isFile ? body : JSON.stringify(body)
    }
    const response = await fetch(url, request)

    if (response && [200, 201, 206].indexOf(response.status) !== -1) {
      const range = response.headers.get('Content-Range')
      const data = await response.json()
      if (newRangeEnd !== undefined && range && returnRange) {
        return { data, range }
      }
      return data
    } else if (response.status === 401) {
      logout()
      return null
    } else {
      const errorData = await response.json()
      if (errorData.status === 400 && returnMessage) {
        return errorData
      }
      throw new Error(errorData.message || 'Erro na chamada à API')
    }
  } catch (error) {
    console.error('Erro ao fazer chamada API:', {
      url,
      method,
      error: error.message,
      stack: error.stack
    })
    throw error
  }
}
