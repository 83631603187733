<template>
  <div class="container-survey-card">
    <div id="top-anchor"></div>
    <div class="loading-card-survey" :ref="`loading-survey-${cardSurvey._id}`" v-show="loadingCardSurvey">
      <Circle2 class="spinner-loading-card-survey"/>
    </div>
    <div class="tags-indicators">
      <div v-for="tag in limitedTags" :key="tag._id" class="tag-indicator" :style="{ backgroundColor: tag.color }" :data-tag-name="tag.name" v-title="tag.name" title-placement="left"></div>
      <div v-if="hasMoreTags" class="more-tags-indicator" v-title="`+${cardSurvey.tags.length - maxVisibleTags} tags`" title-placement="left">
        <span>+</span>
      </div>
    </div>
    <div class="infos-survey-card" @click="editSurvey(cardSurvey, $event)">
      <p class="title-survey-card title-survey"
        :class="`survey-name-${cardSurvey._id}`"
        v-title="$t('surveyList.copy_survey_name')"
        title-placement="right">
        {{ cardSurvey.title }}
      </p>
      <p class="title-survey-card">
        {{ $t('surveyList.created_since') }}:
        <span>{{ formatDate(cardSurvey.createdAt, 'lll') }}</span>
      </p>
      <p class="title-survey-card">
        {{ $t('surveyList.last_send') }}:
        <span>{{ cardSurvey.lastSendAt ? formatDate(cardSurvey.lastSendAt, 'lll') : $t('surveyList.not_send') }}</span>
      </p>
      <p class="title-survey-card" v-title="$t('surveyList.copy_id')" title-placement="right">
        <i class="material-icons icon-hash-survey-list">tag</i>
        <span :class="`id-question-${cardSurvey._id}`">{{ cardSurvey._id }}</span>
      </p>
    </div>
    <div class="status-survey-card" @click="editSurvey(cardSurvey, $event)">
      <div class="square-status-survey-card">
        <p class="label-status-survey-card" :ref="`total-sending-${cardSurvey._id}`">{{ totalSending }}</p>
        <p class="title-status-survey-card">{{ $t('analytics.sendings') }}</p>
      </div>
      <div class="square-status-survey-card">
        <p class="label-status-survey-card" :ref="`total-answers-${cardSurvey._id}`">{{ totalAnswers }}</p>
        <p class="title-status-survey-card">{{ $t('analytics.answers') }}</p>
      </div>
      <div class="square-status-survey-card">
        <p class="label-status-survey-card" :ref="`total-response-time-${cardSurvey._id}`">{{ totalAverageTime }} <span>min</span></p>
        <p class="title-status-survey-card">{{ $t('analytics.time') }}</p>
      </div>
      <div class="square-status-survey-card" :style="{'cursor': cardSurvey.stats.health ? 'pointer' : 'not-allowed'}">
        <div class="heart-survey-list" @click="isRedirect('Overview', cardSurvey?.stats?.health)">
          <i class="material-icons icon-heart-survey-list" :class="`icon-health-${cardSurvey._id}`">favorite</i>
          <p class="label-heart-survey-list" :ref="`total-health-${cardSurvey._id}`" v-title="$t('surveyList.health_survey')" title-placement="bottom"></p>
        </div>
      </div>
    </div>
    <div class="buttons-survey-card">
      <div class="square-button-survey-card" :class="{'button-disabled': !hasInsight}" @click="goToAnalysis(cardSurvey._id)">
        <i class="material-symbols-outlined icon-button-survey-list">memory</i>
        <p class="title-button-survey-card">{{ 'Insights' }}</p>
      </div>
      <div class="square-button-survey-card"
          :class="{'button-disabled': isButtonDisabled(canVisualizeDashboards)}"
          @click="isRedirect('Individual', canVisualizeDashboards)">
        <i class="material-icons-outlined icon-button-survey-list">insert_chart</i>
        <p class="title-button-survey-card">{{ $t('dashboard.individual_responses') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile || cardSurvey.isExpired}" @click="editSurvey(cardSurvey, $event)">
        <i class="material-symbols-outlined icon-button-survey-list">edit_document</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.edit') }}</p>
      </div>
      <div class="square-button-survey-card"
          :class="{'button-disabled': isButtonDisabled(canCreateOrCloneSurveys)}"
          @click="canCreateOrCloneSurveys ? modalCloneSurvey = true : ''">
        <i class="material-icons-outlined icon-button-survey-list">file_copy</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.clone') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" @click="isRedirect('Overview', allowedProfile)">
        <i class="material-symbols-outlined icon-button-survey-list">smart_display</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.tv_dash') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" @click="allowedProfile ? modalDeleteSurvey = true : ''">
        <i class="material-symbols-outlined icon-button-survey-list">delete</i>
        <p class="title-button-survey-card">{{ $t('dashboard_card.delete') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" @click="allowedProfile ? showTagsModal = true : ''">
        <i class="material-symbols-outlined icon-button-survey-list">sell</i>
        <p class="title-button-survey-card">Tags</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': !allowedProfile}" v-if="!cardSurvey.isExpired && !cardSurvey.expiredAt" @click="allowedProfile ? modalExpireSurvey = true : ''">
        <i class="material-symbols-outlined icon-button-survey-list">timer</i>
        <p class="title-button-survey-card">{{ $t('surveyList.expire_survey') }}</p>
      </div>
      <div class="square-button-survey-card" v-if="cardSurvey.expiredAt && !cardSurvey.isExpired" @click="allowedProfile ? modalExpireSurvey = true : ''" v-title="$t('surveyList.expire_in')">
        <i class="material-symbols-outlined icon-button-survey-list">timer</i>
        <p class="title-button-survey-card">{{ formatDate(cardSurvey.expiredAt, 'lll', true) }}</p>
      </div>
      <div class="square-button-survey-card" style="cursor: pointer;" :class="{'button-disabled': cardSurvey.isExpired}" v-if="cardSurvey.expiredAt && cardSurvey.isExpired" @click="allowedProfile ? modalExpireSurvey = true : ''" v-title="$t('surveyList.survey_expired')">
        <i class="material-symbols-outlined icon-button-survey-list">timer_off</i>
        <p class="title-button-survey-card">{{ $t('surveyList.survey_expired') }}</p>
      </div>
      <div class="square-button-survey-card" :class="{'button-disabled': cardSurvey.isExpired}" v-title="cardSurvey.isExpired ? $t('surveyList.survey_expired') : ''">
        <i class="material-icons icon-button-survey-list" @click="sendSurvey()">send</i>
        <p class="title-button-survey-card">{{ $t('seeds.send') }}</p>
      </div>
    </div>

    <!-- MODAL DELETE SURVEY -->
    <app-modal
      class="modal-default-primary confirm-modal-survey"
      v-model="modalDeleteSurvey"
      style="z-index: 99;"
      :title="$t('surveyList.delete_survey')"
      lightbox
      close
      :primary-button="$t('notifications.cancel')"
      @primaryButtonClick="modalDeleteSurvey = false"
      :secondary-button="$t('approaches.delete')"
      @secondaryButtonClick="deleteSurvey()"
    >
      {{ $t("surveyList.delete_question") }}

      <div class="loading-card-survey" v-show="loadingModal">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>

    <!-- MODAL CLONE SURVEY -->
    <app-modal
      class="modal-default-primary confirm-modal-survey"
      v-model="modalCloneSurvey"
      :title="$t('surveyList.clone_survey')"
      lightbox
      close
      :primary-button="$t('notifications.cancel')"
      @primaryButtonClick="modalCloneSurvey = false"
      :secondary-button="$t('surveyList.clone_survey')"
      @secondaryButtonClick="cloneSurvey()"
    >
      <app-form-input
        v-model="nameSurveyToDuplicate"
        :placeholder="$t('start.survey_title_placeholder')"
        secondary
        autofocus
        v-on:keyup.enter="cloneSurvey()"
        v-on:keyup.esc="modalCloneSurvey = false"
      >
      </app-form-input>
      <div class="loading-card-survey" v-show="loadingModal">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>

    <!-- MODAL EXPIRE SURVEY -->
    <app-modal
      :title="cardSurvey.isExpired || cardSurvey.expiredAt ? $t('surveyList.activate_survey') : $t('surveyList.select_date')"
      class="modal-default-primary confirm-modal"
      style="text-align: center;"
      v-model="modalExpireSurvey"
      lightbox
      close
    >
      <div class="row" id="expire-survey-date-picker">
        <div class="col-lg-6 col-md-6 col-sm-6" v-if="!cardSurvey.isExpired && !cardSurvey.expiredAt">
          <v-date-picker
          :min-date='new Date()'
          :locale="$i18n.locale"
          :masks='{inputDateTime24hr: "D MMMM YYYY, H:mm"}'
          v-model="dateToExpireSurvey"
          color="gray" mode="dateTime" is24hr>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="icon-container">
                <i class="material-icons">event</i>
                <input v-on="inputEvents"
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  inputValue readonly :placeholder="dateToExpireSurvey" :value="inputValue"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6  btn">
            <app-button style="width: 100%;" class="btn-default-primary" @click="expireOrActivateSurvey()">
              {{ $t('survey.confirm')}}
            </app-button>
        </div>
      </div>
      <div class="loading-card-survey" v-show="loadingModal">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>

    <!-- MODAL TAGS -->
    <app-modal
      v-model="showTagsModal"
      :title="$t('surveyList.manage_tags')"
      lightbox
      close
      @keyup.esc="showTagsModal = false"
      class="modal-default-primary show-lg show-md show-sm hide-xs hide-xxs"
    >
      <div class="tags-modal-content">
        <!-- Current Tags Section -->
        <div class="tags-section">
          <div class="section-header">
            <h4>{{ $t('surveyList.applied_tags', { count: surveyTags.length }) }}</h4>
          </div>
          <div class="tags-list current-tags">
            <div v-if="!surveyTags.length" class="no-tags-message">
              {{ $t('surveyList.no_tags_applied') }}
            </div>
            <div
              v-for="tag in surveyTags"
              :key="tag._id"
              class="tag-item"
            >
              <div class="tag-info">
                <div class="tag-color" :style="{ backgroundColor: tag.color }"></div>
                <span class="tag-name">{{ tag.name }}</span>
              </div>
              <div class="tag-actions">
                <button class="tag-action edit" @click="openEditTag(tag)" :title="$t('surveyList.edit_tag')">
                  <i class="material-icons">edit</i>
                </button>
                <button class="tag-action remove" @click="removeTag(tag)" :title="$t('surveyList.remove_tag')">
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add New Tag Section -->
        <div class="add-tag-section">
          <button
            class="add-tag-button"
            @click="toggleNewTagForm"
          >
            <i class="material-icons">{{ showNewTagForm ? 'remove' : 'add' }}</i>
            {{ showNewTagForm ? $t('surveyList.cancel') : $t('surveyList.add_new_tag') }}
          </button>
          <div v-if="showNewTagForm" class="new-tag-form">
            <div class="form-group">
              <input
                v-model="newTag.name"
                type="text"
                :placeholder="$t('surveyList.tag_name_placeholder')"
                class="tag-input"
                @keyup.enter="createTag"
              >
              <input
                v-model="newTag.color"
                type="color"
                class="color-picker"
                :title="$t('surveyList.select_color')"
              >
            </div>
            <button
              class="btn-create-tag"
              @click="createTag"
              :disabled="!newTag.name"
            >
              {{ $t('surveyList.create_tag') }}
            </button>
          </div>
        </div>

        <!-- Available Tags Section -->
        <div class="tags-section">
          <div class="section-header">
            <h4>{{ $t('surveyList.available_tags', { count: availableTags.length }) }}</h4>
          </div>
          <div class="tags-list available-tags">
            <div v-if="!availableTags.length" class="no-tags-message">
              {{ $t('surveyList.no_tags_available') }}
            </div>
            <div
              v-for="tag in availableTags"
              :key="tag._id"
              class="tag-item"
            >
              <div class="tag-info">
                <div class="tag-color" :style="{ backgroundColor: tag.color }"></div>
                <span class="tag-name">{{ tag.name }}</span>
              </div>
              <div class="tag-actions">
                <button class="tag-action add" @click="addTag(tag)" :title="$t('surveyList.add_tag')">
                  <i class="material-icons">add</i>
                </button>
                <button class="tag-action edit" @click="openEditTag(tag)" :title="$t('surveyList.edit_tag')">
                  <i class="material-icons">edit</i>
                </button>
                <button class="tag-action delete" @click="confirmDeleteTag(tag)" :title="$t('surveyList.delete_tag')">
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-modal>

    <!-- MODAL CONFIRM DELETE TAG -->
    <app-modal
      v-if="showDeleteTagModal"
      class="modal-default-primary confirm-modal-survey"
      v-model="showDeleteTagModal"
      :title="$t('surveyList.delete_tag_title')"
      lightbox
      close
      :primary-button="$t('surveyList.cancel_button')"
      @primaryButtonClick="cancelDeleteTag"
      :secondary-button="$t('surveyList.delete_button')"
      @secondaryButtonClick="deleteTag"
    >
      {{ $t('surveyList.delete_tag_confirmation', { name: tagToDelete?.name }) }}
    </app-modal>

    <!-- MODAL EDIT TAG -->
    <app-modal
      v-if="showEditTagModal"
      class="modal-default-primary confirm-modal-survey"
      v-model="showEditTagModal"
      :title="$t('surveyList.edit_tag')"
      lightbox
      close
      :primary-button="$t('surveyList.save_button')"
      @primaryButtonClick="saveTagEdit"
      :secondary-button="$t('surveyList.cancel_button')"
      @secondaryButtonClick="cancelTagEdit"
      :disable-primary="loading"
    >
      <div class="edit-tag-form">
        <div class="form-group">
          <input
            v-model="editingTag.name"
            type="text"
            :placeholder="$t('surveyList.tag_name_placeholder')"
            class="tag-input"
            :disabled="loading"
          >
          <input
            v-model="editingTag.color"
            type="color"
            class="color-picker"
            :title="$t('surveyList.select_color')"
            :disabled="loading"
          >
        </div>
      </div>
      <div class="loading-card-survey" v-show="loading">
        <Circle2 class="spinner-loading-card-survey"/>
      </div>
    </app-modal>
  </div>
</template>

<script>
import gsap from 'gsap'
import { sendhubService, surveyService } from '@/_services'
import healthMixins from '@/_mixins/health'
import userMixins from '@/_mixins/user'

export default {
  name: 'CardSurvey',
  props: ['dataSurvey'],
  mixins: [healthMixins, userMixins],
  components: {
    Circle2: () => import('vue-loading-spinner/src/components/Circle2'),
    VDatePicker: () => import('v-calendar/lib/components/date-picker.umd'),
    'app-modal': () => import('@binds-tech/binds-design-system/src/components/Modal/Modal'),
    'app-button': () => import('@binds-tech/binds-design-system/src/components/Button/Button'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input')
  },
  data () {
    return {
      loadingCardSurvey: false,
      loadingModal: false,
      cardSurvey: null,
      totalSending: null,
      totalAnswers: null,
      totalAverageTime: null,
      modalDeleteSurvey: false,
      modalCloneSurvey: false,
      modalExpireSurvey: false,
      modalTags: false,
      nameSurveyToDuplicate: '',
      dateToExpireSurvey: this.$i18n.t('activity.today'),
      showCreateTag: false,
      newTag: {
        name: '',
        color: '#FF6B6B'
      },
      availableTags: [],
      surveyTags: [],
      loading: false,
      tagToDelete: null,
      showDeleteTagModal: false,
      showEditTagModal: false,
      editingTag: {
        _id: null,
        name: '',
        color: ''
      },
      defaultColors: [
        '#AF9AD6', // Roxo claro
        '#9871DD', // Roxo médio
        '#673AB7', // Roxo escuro
        '#64469D', // Roxo profundo
        '#3F3356', // Roxo muito escuro
        '#00AAA7', // Verde água
        '#FFB500', // Amarelo
        '#E4A713', // Amarelo escuro
        '#BA5D1A', // Laranja escuro
        '#FF607D', // Rosa claro
        '#ED4D7F', // Rosa médio
        '#CA1E54', // Rosa escuro
        '#4CAF50', // Verde
        '#2196F3', // Azul
        '#9C27B0', // Roxo vibrante
        '#FF9800', // Laranja
        '#607D8B', // Azul acinzentado
        '#795548', // Marrom
        '#FF5722', // Laranja avermelhado
        '#00BCD4' // Ciano
      ],
      showTagsModal: false,
      showNewTagForm: false,
      maxVisibleTags: 5
    }
  },
  created () {
    this.cardSurvey = this.dataSurvey
    this.totalSending = this.cardSurvey.totalSendings
    this.totalAnswers = this.cardSurvey.totalAnswers
    this.totalAverageTime = this.cardSurvey.responseAverageTime
  },
  async mounted () {
    this.animateNumber('total-sending')
    this.animateNumber('total-answers')

    if (this.cardSurvey.stats.health) {
      this.animateNumber('total-health')
    }
  },
  computed: {
    lang () { return this.$store.getters['account/getLang'] },
    user () { return this.$store.getters['account/getUser'] },
    allowedProfile () { return ['superadmin', 'admin'].includes(this.user.type) || this.user.features.surveyEdit },
    hasInsight () { return this.user.features.insights && this.cardSurvey.isAllowedToAIAnalysis && this.user.type !== 'user' },
    limitedTags () {
      return this.cardSurvey.tags ? this.cardSurvey.tags.slice(0, this.maxVisibleTags) : []
    },
    hasMoreTags () {
      return this.cardSurvey.tags && this.cardSurvey.tags.length > this.maxVisibleTags
    }
  },
  methods: {
    isButtonDisabled (boolToValidate) {
      return !boolToValidate
    },
    sendSurvey () {
      if (this.cardSurvey.isExpired) return

      return this.$router.push({ name: 'Send', params: { id: this.cardSurvey._id } })
    },
    formatDate (date, type, add) {
      const hour = add ? 3 : 0
      this.$moment.locale(this.lang)
      return this.$moment(date).add(hour, 'hours').format(type)
    },
    async editSurvey (survey, event) {
      if (!this.allowedProfile) return

      if (event) {
        const idElement = document.querySelector(`.id-question-${survey._id}`)
        const nameElement = document.querySelector(`.survey-name-${survey._id}`)

        if (idElement && idElement.contains(event.target)) {
          const copyText = idElement.textContent.trim()
          await navigator.clipboard.writeText(copyText)
          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('surveyList.id_copied'),
            position: 'bottomRight',
            showAlert: true,
            persist: false
          })
        }

        if (nameElement && nameElement.contains(event.target)) {
          const copyText = nameElement.textContent.trim()
          await navigator.clipboard.writeText(copyText)
          return this.$store.commit('alerts/alert', {
            message: this.$i18n.t('surveyList.survey_name_copied'),
            position: 'bottomRight',
            showAlert: true,
            persist: false
          })
        }
      }

      if (survey.isExpired) return

      const surveyId = survey._id

      if (this.user.type === 'user' && !this.user.features.surveyEdit) {
        const collector = await sendhubService.collectors()
        const isAgreedSend = collector.find(o => o.survey === surveyId)

        if (isAgreedSend && this.user.features.seedBatches) {
          return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
        }

        return this.$router.push({ name: 'YouCant' })
      }

      return this.$router.push({ name: 'Surveys', params: { id: surveyId } })
    },
    animateNumber (classId) {
      const label = this.$refs[`${classId}-${this.cardSurvey._id}`]

      if (!label) return

      label.innerHTML = 0

      let thisValue = null

      if (classId === 'total-sending') { thisValue = this.totalSending }
      if (classId === 'total-answers') { thisValue = this.totalAnswers }

      if (classId === 'total-health') { thisValue = this.cardSurvey.stats.health }

      gsap.to(label, {
        duration: 1,
        innerHTML: thisValue,
        snap: 'innerHTML',
        ease: 'power1.inOut',
        onUpdate: () => {
          label.innerHTML = Number(label.innerHTML).toLocaleString('de-DE')

          if (classId === 'total-health') { this.checkHealthSurvey() }
        }
      })
    },
    checkHealthSurvey () {
      const iconHeart = document.querySelector(`.icon-health-${this.cardSurvey._id}`)

      if (!iconHeart) return

      if (this.cardSurvey.stats.health <= 15) {
        gsap.to(iconHeart, {
          scale: 1.02,
          duration: 0.4,
          repeat: -1,
          yoyo: true,
          ease: 'power1.inOut'
        })
      }

      const colorDynamic = this.getColorHeartSurvey(this.cardSurvey.stats.health)

      iconHeart.style.color = colorDynamic
      iconHeart.style.filter = `drop-shadow(0 0 2px ${colorDynamic})`
    },
    goToAnalysis (surveyId) {
      if (!this.hasInsight) return

      this.$store.commit('filtersInsight/insightField', { surveyId })
      return this.$router.push({ name: 'Insights' })
    },
    deleteSurvey () {
      this.loadingCardSurvey = true
      this.modalDeleteSurvey = false
      return this.$emit('delete-survey', this.cardSurvey._id)
    },
    isRedirect (toPage, allowed) {
      if (!allowed) return
      return this.$emit('to-redirect', { location: toPage, surveyId: this.cardSurvey._id })
    },
    cloneSurvey () {
      if (!this.nameSurveyToDuplicate.length) {
        return this.$store.commit('alerts/alert', {
          message: this.$i18n.t('surveyList.type_title'),
          position: 'bottomRight',
          showAlert: true
        })
      }
      this.loadingModal = true
      this.modalCloneSurvey = false
      return this.$emit('to-duplicate', { surveyId: this.cardSurvey._id, name: this.nameSurveyToDuplicate.trim() })
    },
    async expireOrActivateSurvey () {
      this.loadingModal = true
      this.setLoading()

      try {
        const isToExpire = !this.cardSurvey.isExpired && !this.cardSurvey.expiredAt
        const parsedDateToSend = this.dateToExpireSurvey === this.$i18n.t('activity.today') ? new Date() : this.dateToExpireSurvey

        const result = await surveyService.expireOrActivateSurvey(this.cardSurvey._id, isToExpire ? this.$moment(parsedDateToSend).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '')
        if (result) {
          this.cardSurvey.isExpired = result.isExpired
          this.cardSurvey.expiredAt = result.expiredAt

          return this.$store.commit('alerts/alert', {
            message: isToExpire ? this.$i18n.t('surveyList.success_expire') : this.$i18n.t('surveyList.success_activate'),
            position: 'bottomRight',
            showAlert: true
          })
        }
      } catch (error) {
        console.error('Error', error)
      } finally {
        this.modalExpireSurvey = false
        this.loadingModal = false
        this.setLoading(true)
      }
    },
    setLoading (close) {
      if (!close) { this.loadingCardSurvey = true }

      const divLoading = this.$refs[`loading-survey-${this.cardSurvey._id}`]
      gsap.to(divLoading, { duration: 0.5, onComplete: () => { if (close) { this.loadingCardSurvey = false } } })
    },
    async loadTags () {
      try {
        this.loading = true
        console.log('Iniciando carregamento de tags...')
        // Busca todas as tags disponíveis
        const allTags = await surveyService.getAllTags()
        console.log('Tags disponíveis:', allTags)
        // Define as tags do survey
        this.surveyTags = [...(this.cardSurvey.tags || [])]
        // Filtra as tags disponíveis removendo as que já estão aplicadas
        this.availableTags = (allTags || []).filter(tag =>
          !this.surveyTags.some(surveyTag => surveyTag._id === tag._id)
        )
        console.log('Tags disponíveis filtradas:', this.availableTags)
        // Ordena as tags
        this.surveyTags.sort((a, b) => a.name.localeCompare(b.name))
        this.availableTags.sort((a, b) => a.name.localeCompare(b.name))
        // Força a atualização do componente
        this.$forceUpdate()
        // Emite evento para atualizar o componente pai
        this.$emit('refresh')
      } catch (error) {
        console.error('Erro ao carregar tags:', error)
        this.$toast.error(this.$t('surveyList.error_loading_tags'))
      } finally {
        this.loading = false
      }
    },
    isTagSelected (tagId) {
      return this.surveyTags.some(tag => tag._id === tagId)
    },
    suggestDifferentColor () {
      // Obtém todas as cores já em uso (tanto nas tags do survey quanto nas disponíveis)
      const usedColors = new Set([
        ...this.surveyTags.map(tag => tag.color),
        ...this.availableTags.map(tag => tag.color)
      ])

      // Encontra a primeira cor disponível que não está em uso
      const availableColor = this.defaultColors.find(color => !usedColors.has(color))

      // Se todas as cores padrão estiverem em uso, gera uma cor aleatória
      if (!availableColor) {
        // Gera uma cor HSL aleatória com saturação e luminosidade controladas
        const hue = Math.floor(Math.random() * 360) // Matiz aleatório
        const saturation = Math.floor(Math.random() * 20 + 60) // Saturação entre 60% e 80%
        const lightness = Math.floor(Math.random() * 20 + 40) // Luminosidade entre 40% e 60%
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`
      }

      return availableColor
    },
    async createTag () {
      try {
        this.loading = true
        const newTag = await surveyService.createTag(this.newTag)
        // Adiciona a nova tag diretamente à lista de tags disponíveis
        this.availableTags.push(newTag)
        // Ordena as tags disponíveis
        this.availableTags.sort((a, b) => a.name.localeCompare(b.name))
        // Limpa o formulário e sugere uma nova cor diferente
        this.showNewTagForm = false
        this.newTag = { name: '', color: this.suggestDifferentColor() }
        this.$toast.success(this.$t('surveyList.tag_created'))
        // Força atualização das listas
        await this.loadTags()
      } catch (error) {
        this.$toast.error(this.$t('surveyList.error_creating_tag'))
        console.error('Error creating tag:', error)
      } finally {
        this.loading = false
      }
    },
    async toggleTag (tag) {
      try {
        this.loading = true
        if (this.isTagSelected(tag._id)) {
          await surveyService.removeTagFromSurvey(this.cardSurvey._id, tag._id)
          this.$toast.success(this.$t('surveyList.tag_removed'))
          // Remove a tag das tags da pesquisa e adiciona às disponíveis
          const index = this.surveyTags.findIndex(t => t._id === tag._id)
          if (index !== -1) {
            const removedTag = this.surveyTags.splice(index, 1)[0]
            this.availableTags.push(removedTag)
            // Atualiza as tags do card
            this.cardSurvey.tags = [...this.surveyTags]
            // Ordena as tags disponíveis por nome
            this.availableTags.sort((a, b) => a.name.localeCompare(b.name))
            // Emite eventos para atualização
            this.$emit('update-facets')
            this.$emit('update-survey', this.cardSurvey)
            this.$emit('refresh')
            this.$emit('reset-pagination')
            this.scrollToTop()
          }
        } else {
          await surveyService.addTagToSurvey(this.cardSurvey._id, tag._id)
          this.$toast.success(this.$t('surveyList.tag_added'))
          // Adiciona a tag às tags da pesquisa e remove das disponíveis
          const index = this.availableTags.findIndex(t => t._id === tag._id)
          if (index !== -1) {
            const addedTag = this.availableTags.splice(index, 1)[0]
            this.surveyTags.push(addedTag)
            // Atualiza as tags do card
            this.cardSurvey.tags = [...this.surveyTags]
            // Ordena as tags da pesquisa por nome
            this.surveyTags.sort((a, b) => a.name.localeCompare(b.name))
            // Emite eventos para atualização
            this.$emit('update-facets')
            this.$emit('update-survey', this.cardSurvey)
            this.$emit('refresh')
            this.$emit('reset-pagination')
            this.scrollToTop()
          }
        }
        // Força a atualização da lista de tags no modal e no card
        await this.loadTags()
        // Força a atualização do componente pai
        this.$emit('refresh')
      } catch (error) {
        console.error('Erro ao alternar tag:', error)
        this.$toast.error(this.$t('surveyList.error_updating_tags'))
      } finally {
        this.loading = false
      }
    },
    confirmDeleteTag (tag) {
      this.tagToDelete = tag
      this.showDeleteTagModal = true
    },
    cancelDeleteTag () {
      this.tagToDelete = null
      this.showDeleteTagModal = false
    },
    async deleteTag () {
      try {
        this.loading = true
        await surveyService.deleteTag(this.tagToDelete._id)
        await this.loadTags()
        this.showDeleteTagModal = false
        this.tagToDelete = null
        // Atualiza a cor sugerida após remover uma tag
        if (this.showNewTagForm) {
          this.newTag.color = this.suggestDifferentColor()
        }
        this.$toast.success(this.$t('surveyList.tag_deleted'))
      } catch (error) {
        this.$toast.error(this.$t('surveyList.error_deleting_tag'))
        console.error('Error deleting tag:', error)
      } finally {
        this.loading = false
      }
    },
    scrollToTop () {
      console.log('Emitindo evento scroll-to-top')
      this.$emit('scroll-to-top')
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    async saveTags () {
      try {
        // Atualiza as tags do card
        this.cardSurvey.tags = [...this.surveyTags]
        // Emite eventos para atualização
        this.$emit('update-facets')
        this.$emit('update-survey', this.cardSurvey)
        this.$emit('refresh')
        this.$emit('reset-pagination')
        this.showTagsModal = false
        // Força o scroll após as atualizações
        await this.$nextTick()
        this.scrollToTop()
        this.$toast.success(this.$t('surveyList.tags_saved'))
      } catch (error) {
        this.$toast.error(this.$t('surveyList.error_saving_tags'))
        console.error('Error saving tags:', error)
      }
    },
    async removeTag (tag) {
      try {
        const button = event.target.closest('.tag-action')
        if (button) {
          button.disabled = true
        }
        await surveyService.removeTagFromSurvey(this.cardSurvey._id, tag._id)
        // Remove a tag das selecionadas e adiciona às disponíveis
        const index = this.surveyTags.findIndex(t => t._id === tag._id)
        if (index !== -1) {
          const removedTag = this.surveyTags.splice(index, 1)[0]
          this.availableTags.push(removedTag)
          // Atualiza as tags do card imediatamente
          this.cardSurvey.tags = [...this.surveyTags]
          // Ordena as tags
          this.availableTags.sort((a, b) => a.name.localeCompare(b.name))
          // Emite eventos para atualização
          this.$emit('update-facets')
          this.$emit('update-survey', this.cardSurvey)
          this.$emit('refresh')
          this.$emit('reset-pagination')
          // Força o scroll após as atualizações
          await this.$nextTick()
          this.scrollToTop()
        }
        this.$toast.success(this.$t('surveyList.tag_removed'))
      } catch (error) {
        console.error('Erro ao remover tag:', error)
        this.$toast.error(this.$t('surveyList.error_removing_tag'))
      } finally {
        const button = event.target.closest('.tag-action')
        if (button) {
          button.disabled = false
        }
      }
    },
    async addTag (tag) {
      try {
        const button = event.target.closest('.tag-action')
        if (button) {
          button.disabled = true
        }
        this.loading = true
        // Chama a API para adicionar a tag
        await surveyService.addTagToSurvey(this.cardSurvey._id, tag._id)
        // Remove a tag das disponíveis e adiciona às selecionadas
        const index = this.availableTags.findIndex(t => t._id === tag._id)
        if (index !== -1) {
          const addedTag = this.availableTags.splice(index, 1)[0]
          this.surveyTags.push(addedTag)
          // Atualiza as tags do card imediatamente
          this.cardSurvey.tags = [...this.surveyTags]
          // Ordena as tags
          this.surveyTags.sort((a, b) => a.name.localeCompare(b.name))
          // Força a atualização do estado
          this.$forceUpdate()
          // Emite eventos para atualização em cascata
          this.$emit('update-facets')
          this.$emit('update-survey', this.cardSurvey)
          this.$emit('refresh')
          this.$emit('reset-pagination')
          // Força o scroll após as atualizações
          await this.$nextTick()
          this.scrollToTop()
          this.$toast.success(this.$t('surveyList.tag_added'))
        } else {
          console.error('Tag não encontrada nas tags disponíveis')
          this.$toast.error(this.$t('surveyList.error_adding_tag'))
        }
      } catch (error) {
        console.error('Erro ao adicionar tag:', error)
        this.$toast.error(this.$t('surveyList.error_adding_tag'))
      } finally {
        const button = event.target.closest('.tag-action')
        if (button) {
          button.disabled = false
        }
        this.loading = false
      }
    },
    toggleNewTagForm () {
      this.showNewTagForm = !this.showNewTagForm
      if (!this.showNewTagForm) {
        // Limpa o formulário e sugere nova cor quando fecha
        this.newTag = {
          name: '',
          color: this.suggestDifferentColor()
        }
      }
    },
    async openEditTag (tag) {
      // Reseta o estado de loading antes de abrir o modal
      this.loading = false
      this.editingTag = {
        _id: tag._id,
        name: tag.name,
        color: tag.color
      }
      this.showEditTagModal = true
    },
    cancelTagEdit () {
      this.loading = false // Garante que o loading é resetado ao cancelar
      this.editingTag = {
        _id: null,
        name: '',
        color: ''
      }
      this.showEditTagModal = false
    },
    async saveTagEdit () {
      if (!this.editingTag.name.trim()) {
        this.$toast.error(this.$t('surveyList.tag_name_required'))
        return
      }

      try {
        this.loading = true
        const updatedTag = await surveyService.updateTag(this.editingTag._id, {
          name: this.editingTag.name.trim(),
          color: this.editingTag.color
        })

        // Atualiza a tag nas listas
        const updateTagInList = (list) => {
          const index = list.findIndex(t => t._id === updatedTag._id)
          if (index !== -1) {
            list[index] = { ...list[index], ...updatedTag }
          }
        }

        updateTagInList(this.availableTags)
        updateTagInList(this.surveyTags)

        // Atualiza as tags do card se necessário
        if (this.cardSurvey.tags) {
          updateTagInList(this.cardSurvey.tags)
        }
        await this.loadTags()
        this.$toast.success(this.$t('surveyList.tag_updated'))
      } catch (error) {
        console.error('Erro ao atualizar tag:', error)
        this.$toast.error(this.$t('surveyList.error_updating_tag'))
      } finally {
        this.loading = false
        this.showEditTagModal = false
      }
    }
  },
  watch: {
    showTagsModal: {
      immediate: true,
      async handler (newValue) {
        if (newValue) {
          await this.loadTags()
        }
      }
    },
    showNewTagForm: {
      immediate: false,
      handler (newValue) {
        if (newValue) {
          this.newTag.color = this.suggestDifferentColor()
        } else {
          this.newTag = {
            name: '',
            color: this.suggestDifferentColor()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#top-anchor {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
}

#expire-survey-date-picker {
  justify-content: center;
  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    padding: 16px !important;
    border: 1px solid #c4c4c4;
    box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  }
  .icon-container {
    display: flex;
    align-items: center;
  }
  .material-icons {
    padding: 5px;
    position: absolute;
  }
}

.loading-card-survey {
  background: #ffffff;
  position: absolute;
  border-radius: 15px;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-loading-card-survey {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-width: 3px !important;
    border-radius: 35px;
    border-color: var(--accent-color) #e1e1e1 #e1e1e1 !important;
  }
}

.container-survey-card {
  position: relative;
  height: 7.8vw;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  box-shadow: 0 5px 7px 0 rgba(48, 48, 48, 0.16), 0 5px 4px 0 rgba(0, 0, 0, 0.01);
  padding: 0.8vw;
  padding-left: calc(8px + 0.8vw);
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .tags-indicators {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px 0 0 8px;

    .tag-indicator {
      width: 5px;
      flex: 1;
      position: relative;
    }

    .more-tags-indicator {
        display: flex;
        justify-content: center;
        margin-bottom: 9px;
        span {
          position: relative;
          top: 0;
          left: 0.3px;
          font-size: 10.6px;
          font-weight: bold;
        }
      }
  }

  .infos-survey-card {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 8px;
    margin-left: 0;
    .title-survey-card {
      width: fit-content;
      font-size: 0.75vw;
      font-weight: 500;
      color: #454548;
      display: flex;
      align-items: center;
      gap: 0.3vw;
      .icon-hash-survey-list {
        font-size: 1vw;
        color: #5e5e5e;
      }
      span {
        font-weight: 400;
      }
    }
    .title-survey {
      font-weight: 600;
      font-size: 1.05vw;
      line-height: 1.4vw;
      width: fit-content;
      max-width: 100%;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .status-survey-card {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .square-status-survey-card {
      color: #454548;
      min-width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1vw;
      .label-status-survey-card {
        font-size: 1.1vw;
        font-weight: 700;
        span {
          font-weight: 500;
          font-size: 0.85vw;
        }
      }
      .title-status-survey-card {
        font-size: 0.75vw;
      }
      .heart-survey-list {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-heart-survey-list {
          font-size: 3.5vw;
          color: #f1f1f1;
          filter: drop-shadow(0 0 2px #f1f1f1);
        }
        .label-heart-survey-list {
          position: absolute;
          color: #ffffff;
          font-weight: 700;
          font-size: 1.2vw;
          top: 1vw;
        }
      }
    }
  }
  .buttons-survey-card {
    user-select: none;
    cursor: default;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .square-button-survey-card {
      cursor: pointer;
      height: 3.2vw;
      color: #454548;
      min-width: 9%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .icon-button-survey-list {
        font-size: 1.5vw;
      }
      .title-button-survey-card {
        font-size: 0.75vw;
        text-align: center;
      }
    }
    .square-button-survey-card:hover {
      color: var(--accent-color);
      transform: scale(1.01);
    }
    .button-disabled {
      color: #e1e1e1;
      cursor: not-allowed;
    }
    .button-disabled:hover {
      color: #e1e1e1;
      transform: scale(1);
    }
  }
}

.tags-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 0;
  max-height: 80vh;
  overflow-y: auto;

  .tags-section {
    .section-header {
      margin-bottom: 8px;

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-color);
      }
    }

    .tags-list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-height: 140px;
      overflow-y: auto;
      padding: 4px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: var(--background-color);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--accent-color);
        border-radius: 4px;
      }
    }
  }

  .tag-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    transition: background-color 0.2s ease;

    &:hover {
      background: var(--background-color-hover);
    }

    .tag-info {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;

      .tag-color {
        width: 10px;
        height: 10px;
        border-radius: 3px;
        flex-shrink: 0;
      }

      .tag-name {
        font-size: 13px;
        color: var(--text-color);
        font-weight: 500;
      }
    }

    .tag-actions {
      display: flex;
      gap: 4px;
      margin-left: 8px;

      .tag-action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;

        &.add {
          background: var(--accent-color-light);
          color: var(--accent-color);

          &:hover {
            background: var(--accent-color);
            color: white;
          }

          &:disabled {
            opacity: 0.5;
            cursor: wait;
          }
        }

        &.delete {
          background: var(--background-color-secondary);
          color: var(--text-color-secondary);

          &:hover {
            background: var(--error-color);
            color: var(--text-color-secondary);
          }

          &:disabled {
            opacity: 0.5;
            cursor: wait;
          }
        }

        &.edit {
          background: var(--background-color-secondary);
          color: var(--text-color-secondary);

          &:hover {
            background: var(--accent-color);
            color: white;
          }

          &:disabled {
            opacity: 0.5;
            cursor: wait;
          }
        }

        i {
          font-size: 16px;
          pointer-events: none;
        }
      }
    }
  }

  .add-tag-section {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 12px 0;

    .add-tag-button {
      display: flex;
      align-items: center;
      gap: 8px;
      background: transparent;
      border: 1px dashed var(--accent-color);
      color: var(--accent-color);
      padding: 6px 12px;
      border-radius: 6px;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      width: 100%;
      transition: all 0.2s ease;

      &:hover {
        background: var(--accent-color-light);
      }

      i {
        font-size: 18px;
      }
    }

    .new-tag-form {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .form-group {
        display: flex;
        gap: 8px;

        .tag-input {
          flex: 1;
          padding: 6px 10px;
          border: 1px solid var(--border-color);
          border-radius: 4px;
          font-size: 13px;
          color: var(--text-color);

          &:focus {
            border-color: var(--accent-color);
            outline: none;
          }
        }

        .color-picker {
          width: 32px;
          height: 32px;
          padding: 2px;
          border: 1px solid var(--border-color);
          border-radius: 4px;
          cursor: pointer;

          &::-webkit-color-swatch-wrapper {
            padding: 0;
          }

          &::-webkit-color-swatch {
            border: none;
            border-radius: 2px;
          }
        }
      }

      .btn-create-tag {
        padding: 6px 12px !important;
        font-size: 14px !important;
        min-width: 160px !important;
        max-width: 160px !important;
        margin: 0 auto !important;
        background-color: var(--accent-color) !important;
        color: white !important;
        border: none !important;
        border-radius: 4px !important;
        cursor: pointer !important;
        transition: all 0.2s ease !important;

        &:hover {
          opacity: 0.9 !important;
        }

        &:disabled {
          opacity: 0.5 !important;
          cursor: not-allowed !important;
        }
      }
    }
  }

  .no-tags-message {
    text-align: center;
    padding: 12px;
    color: var(--text-color-secondary);
    font-size: 13px;
    background: var(--background-color);
    border: 1px dashed var(--border-color);
    border-radius: 6px;
  }
}

.tag-actions {
    display: flex;
  gap: 4px;
}

.tag-action.delete {
  background: var(--background-color-secondary);
  color: var(--text-color-secondary);
  &:hover {
    background: var(--error-color);
    color: var(--text-color-secondary);
  }
}

.modal-default-primary.show-lg.show-md.show-sm {
  .modal-footer {
    padding: 16px;
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    gap: 16px;

    button {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      max-width: 200px !important;
      width: 100% !important;
      height: 40px !important;
      padding: 8px 16px !important;
      border-radius: 4px !important;
      font-size: 13px !important;
      font-weight: 500 !important;
      cursor: pointer !important;
      gap: 8px !important;
      transition: background 0.2s ease !important;
      opacity: 1 !important;

      &.primary {
        background: var(--accent-color) !important;
        color: white !important;
        border: none !important;

        &:hover, &:active, &:focus {
          background: var(--accent-color-dark) !important;
          opacity: 1 !important;
        }

        &:disabled {
          background: var(--background-color-secondary) !important;
          cursor: not-allowed !important;
          opacity: 1 !important;
        }
      }

      &.secondary {
        background: transparent !important;
        border: 1px solid var(--accent-color) !important;
        color: var(--accent-color) !important;

        &:hover, &:active, &:focus {
          background: var(--accent-color-light) !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

// Estilo específico para as tabs
:deep(.tab-item .tag-color-indicator) {
  width: 5px;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px 0 0 0;
}

.facets-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .facet-item {
    height: 32px;
    min-width: 120px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--background-color-hover);
    }

    &.active {
      background: var(--accent-color);
      border-color: var(--accent-color);
      color: white;
    }

    .facet-count {
      margin-left: 6px;
      font-size: 12px;
      color: var(--text-color-secondary);
    }

    &.active .facet-count {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .facet-button {
    height: 32px;
    min-width: 120px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--background-color-hover);
    }

    &.active {
      background: var(--accent-color);
      border-color: var(--accent-color);
      color: white;
    }
  }

  .facet-input {
    height: 32px;
    min-width: 200px;
    padding: 0 12px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    color: var(--text-color);
    transition: all 0.2s ease;

    &:focus {
      border-color: var(--accent-color);
      outline: none;
    }
  }
}

.filters {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  .filter-item {
    height: 32px;
    min-width: 120px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--background-color-hover);
    }

    &.active {
      background: var(--accent-color);
      border-color: var(--accent-color);
      color: white;
    }

    .filter-count {
      margin-left: 6px;
      font-size: 12px;
      color: var(--text-color-secondary);
    }

    &.active .filter-count {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .filter-input {
    height: 32px;
    min-width: 200px;
    padding: 0 12px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    color: var(--text-color);
    transition: border-color 0.2s ease;

    &:focus {
      border-color: var(--accent-color);
      outline: none;
    }

    &::placeholder {
      color: var(--text-color-secondary);
    }
  }

  .filter-select {
    height: 32px;
    min-width: 150px;
    padding: 0 12px;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 13px;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--background-color-hover);
    }

    &:focus {
      border-color: var(--accent-color);
      outline: none;
    }
  }
}

.edit-tag-form {
  padding: 16px;
  .form-group {
    display: flex;
    gap: 12px;
    align-items: center;
    .tag-input {
      flex: 1;
      height: 36px;
      padding: 0 12px;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      font-size: 14px;
      color: var(--text-color);
      &:focus {
        border-color: var(--accent-color);
        outline: none;
      }
    }
    .color-picker {
      width: 36px;
      height: 36px;
      padding: 2px;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      cursor: pointer;
      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      &::-webkit-color-swatch {
        border: none;
        border-radius: 2px;
      }
    }
  }
}
</style>
